import { Airport } from './airport';
import { Departament } from './departament';
import { Unit } from './unit';
import { Fleet } from './fleet';
import { TypeState } from './typeState';
import { DailyUseHistory } from './dailyUseHistory';
import { MaintHistory } from './maintHistory';
import { Service } from './service';
import { FleetFamily } from './fleetFamily';
import { ComponentAircraft } from './component';
import { Accesories } from './accesories';
import { MachineHistory } from './machineHistory';

import { FailReport } from './failReport';
import { UnitHistory } from './unitHistory';
import { Command } from './command';
import { UseHistoryAugmented } from './useHistoryAugmented';
import { UseHistory } from './useHistory';
import { WorkPackage } from './workPackage';
import { MachineDocument } from './machinedocument';
import { MachineImage } from './machineimage';
import { AssetStatus } from './assetstatus';
import { ControllerPlan } from './controllerPlan';
import { ControllerUsed } from './controllerUsed';
import { SubUnit } from './subUnit';
import { ControllerMonitoreo } from '../Interfaces/controller-monitoreo';
import { SumValuesResponse } from 'src/app/models/Interfaces/sum-values-response';
import { ServiceAssigMachine } from '../Interfaces/service-assig-machine';

export class Machine {

	/*1*/ 	public id?: number;
	/*2*/ 	public callSing?: string;
	/*3*/ 	public serieNumber?: string;
	/*4*/ 	public fleetID?: number;
	/*5*/ 	public fleetFamilyID?: number;
	/*6*/ 	public currentHoursUse?: number = 0;
	/*7*/	public currentCycleUse?: number = 0;
	/*16*/	public currentLandingUse?: number = 0;
	/*8*/   public unitID?: number;
	/*9*/   public hoursAsigned?: number;
	/*10*/  public dateFabric?: Date;
	/*11*/  public dateEnroll?: Date;
	/*12*/	public descript?: string;
	/*13*/	public typeStateID?: number;
	/*14*/	public departamentID?: number;
	/*15*/	public airportID?: number;
	/*16*/	public commandID?: number;

	/*33*/	public fixedUnitID?: number;

	/*10*/  public isfail?: Boolean;
	/*11*/  public isUpdate?: Boolean;
	public totalHoursToday?: number;
	/*12*/	public timezone?: string;
	/*13*/	public totalHoursUse?: number;
	/*14*/	public totalCycleUse?: number;
	/*15*/	public totalLandingUse?: number;


	/*17*/	public fleet?: Fleet;
	/*18*/	public fleetFamily?: FleetFamily;
	/*19*/	public airport?: Airport;
	/*20*/	public components?: ComponentAircraft[];
	/*21*/	public accesories?: Accesories;
	/*22*/	public dailyUseHistories?: Array<DailyUseHistory>;
	/*23*/	public subunit?: SubUnit;
	/*24*/	public typeState?: TypeState;
	/*25*/	public machineHistories?: MachineHistory;
	/*26*/	public WorkPackages?: WorkPackage[];
	/*26*/	public maintHistories?: MaintHistory[];
	/*27*/	public departament?: Departament;
	/*28*/	public command?: Command;
	/*29*/	public failReports?: FailReport;

	/*30*/	public activated?: boolean;
	/*31*/	public activatedTwo?: boolean;
	/*32*/  public maintCmpActivities?: Array<Service>;
	/*23*/	public unitfix?: Unit;

	public useHistoryAugmenteds?: Array<UseHistoryAugmented>;

	public edit?: boolean; //Para editar en parte diario
	public newUseHistory?: UseHistory; //Para editar en parte diario

	public totalDayUse?: number;
	/*34*/		public unitHistories?: Array<UnitHistory>;
	/*35*/		public pernocta?: { airport?: Airport, departament?: Departament };

	public machinedocument?: Array<MachineDocument>;
	public machineimage?: Array<MachineImage>;

	public ano?: number;

	public hoursAsignedMonth?:number = 0
	public hoursFlewMonth?:number = 0
	public hoursAvailableMonth?:number = 0
	public hoursDays?:number = 0
	public hoursUseHistories?:number = 0
	public ciclesUseHistories?:number = 0
	public comparadorDayWithToday?:number = 0
	public landingUseHistories?:number = 0

	public userregisted?: number
	public noteregisted?: string	
	public dateregisted?: Date

	public userupdated?: number
	public dateupdated?: Date

	public userdown?: number
	public datedown?: Date
	public notedown?: string	
	
	public assetstatusid?: number
	public assetstatus?: AssetStatus;
	public comentEnd?: string

	public controllerplan?: ControllerPlan[] = [];
	public controlleruse?: ControllerUsed[] = [];

	public hasStartController?: boolean = false

	public controllermonitoreos?: ControllerMonitoreo[] = []
	public serviceassigmachines?: ServiceAssigMachine[] = []

	// Temporal used
	public sumValuesResponse?: SumValuesResponse[] = []

	constructor(m?: any) {
		this.id = m ? m.id : null;
		this.callSing = m ? m.callSing : null;
		this.serieNumber = m ? m.serieNumber : null;
		this.fleetID = m ? m.fleetID : null;
		this.fleetFamilyID = m ? m.fleetFamilyID : null;
		this.currentHoursUse = m ? m.currentHoursUse : null;
		this.currentCycleUse = m ? m.currentCycleUse : null;
		this.unitID = m ? m.unitID : null;
		this.hoursAsigned = m ? m.hoursAsigned : null;
		this.dateFabric = m ? m.dateFabric : null;
		this.dateEnroll = m ? m.dateEnroll : null;
		this.descript = m ? m.descript : null;
		this.typeStateID = m ? m.typeStateID : null;
		this.departamentID = m ? m.departamentID : null;
		this.airportID = m ? m.airportID : null;
		this.commandID = m ? m.commandID : null;
		this.fleet = (m && m.fleet) ? new Fleet(m.fleet) : null;
		this.fleetFamily = (m && m.fleetFamily) ? new FleetFamily(m.fleetFamily) : null;
		this.airport = (m && m.airport) ? new Airport(m.airport) : null;
		this.components = (m && m.components) ? new Array<ComponentAircraft>(m.components) : null;
		this.accesories = (m && m.accesories) ? new Accesories(m.accesories) : null;
		this.dailyUseHistories = (m && m.dailyUseHistories) ? new Array<DailyUseHistory>(m.dailyUseHistories) : null;
		this.subunit = (m && m.subunit) ? m.subunit : null;
		this.typeState = (m && m.typeState) ? new TypeState(m.typeState) : null;

		this.machineHistories = (m && m.machineHistories) ? new MachineHistory(m.machineHistories) : null;
		this.maintHistories = (m && m.maintHistories) ? m.maintHistories : [];
		this.departament = (m && m.Departament) ? new Departament(m.departament) : null;
		this.command = (m && m.command) ? new Command(m.command) : null;
		this.failReports = (m && m.failReports) ? new FailReport(m.failReports) : null;

		this.maintCmpActivities = (m && m.maintCmpActivities) ? new Array<Service>(m.maintCmpActivities) : null;
		this.unitHistories = (m && m.unitHistories) ? new Array<UnitHistory>(m.unitHistories) : null;
		this.activated = false;
		this.activatedTwo = false;
		this.fixedUnitID = m ? m.fixedUnitID : null;
		this.totalHoursToday = 0
		//this.calculateTodayHours();
		this.ano = m ? m.ano : null;

		this.machinedocument = (m && m.machinedocument) ? new Array<MachineDocument>(m.machinedocument) : null;
		this.machineimage = (m && m.machineimage) ? new Array<MachineImage>(m.machineimage) : null;

		this.userregisted = m ? m.userregisted : null;
		this.noteregisted = m ? m.noteregisted : null;
		this.dateregisted = m ? m.dateregisted : null;

		this.userupdated = m ? m.userupdated : null;
		this.dateupdated = m ? m.dateupdated : null;

		this.userdown = m ? m.userdown : null;
		this.datedown = m ? m.datedown : null;
		this.notedown = m ? m.notedown : null;

		this.assetstatusid = m ? m.assetstatusid : null;
		this.assetstatus = (m && m.assetstatus) ? new AssetStatus(m.assetstatus) : null;
		this.unitfix = (m && m.unitfix) ? new Unit(m.unitfix) : null;

		this.controllerplan = (m && m.controllerplan) ? new Array<ControllerPlan>(m.controllerplan) : [];
		this.controlleruse = (m && m.controlleruse) ? new Array<ControllerUsed>(m.controlleruse) : [];

		this.controllermonitoreos = (m && m.controllermonitoreos) ? new Array<ControllerMonitoreo>(m.controllermonitoreos) : [];
		this.serviceassigmachines = (m && m.serviceassigmachines) ? new Array<ServiceAssigMachine>(m.serviceassigmachines) : [];
	}

	calculateTodayHours?(){
		if (!!this.useHistoryAugmenteds && this.useHistoryAugmenteds.length > 0) {
			this.totalHoursToday = this.useHistoryAugmenteds.reduce(function (total, currentValue) {
				return total + currentValue.hoursUse;
			}, 0);
		}
		else {
			this.totalHoursToday = 0
		}
	}

}
