import { MaintStandard } from '../Interfaces/maint-standard';
import { ServiceAssigMachine } from '../Interfaces/service-assig-machine';
import { FleetFamily } from './fleetFamily';
import { Machine } from './machine';
import { Manufacture } from './manufacture';
import { RoleMachine } from './roleMachine';
import { TypeState } from 'src/app/models/class/typeState';

export class Fleet {

	/*1*/ 	public id: number;
	/*2*/ 	public fleetName: string;
	/*3*/ 	public fleetAcronim: string;
	/*4*/ 	public manufactureID: number;
	/*5*/ 	public roleMachineID: number;
	/*6*/ 	public fleetFamilyID: number;
	/*7*/ 	public fleetIco: string;
	/*8*/   public fleetFamily: FleetFamily;
	/*9*/	public manufacture: Manufacture;
	/*10*/	public roleMachine: RoleMachine;
	
	/*11*/	public totalmachines?:number;
	/*12*/	public machines?:Machine[]
	/*13*/	public typeStates?:TypeState[]

	/*15*/	public activotype?: number

	public standardid?: number
	public maintstandard?: MaintStandard

	public serviceassigmachines?: ServiceAssigMachine[]

	constructor(f ?: any) {
		this.id = f ? f.id : null;
		this.fleetName = f ? f.fleetName : null;
		this.fleetAcronim = f ? f.fleetAcronim : null;
		this.manufactureID = f ? f.manufactureID : null;
		this.roleMachineID = f ? f.roleMachineID : null;
		this.fleetFamilyID = f ? f.fleetFamilyID : null;
		this.fleetIco = f ? f.fleetIco : null;
		this.fleetFamily = (f && f.fleetFamily) ? new FleetFamily(f.fleetFamily) : null;
		this.manufacture = (f && f.manufacture) ? new Manufacture(f.manufacture) : null;
		this.activotype = f ? f.activotype : null;
		this.standardid = f ? f.standardid : null;
		this.maintstandard = (f && f.maintstandard) ? f.maintstandard : null;
		this.serviceassigmachines = (f && f.serviceassigmachines) ? f.serviceassigmachines : null;
	}

}
