<router-outlet></router-outlet>

<!--*******************+  Incio de Modal Loader *****************-->
<div
  class="fullScreen center"
  [ngClass]="{ 'fullScreen--activated': modalLoaderState }">
  <app-loader *ngIf="modalLoaderState"></app-loader>
</div>
<!--*******************+  Fin de Modal Loader *****************-->

<!--*******************+  Incio de Modal Mesages *****************-->
<div
  class="fullScreen center"
  (click)="closeModal()"
  [ngClass]="{ 'fullScreen--activated': modalMessageState }">
  <app-message *ngIf="modalMessageState" [messageData]="dataMsm"></app-message>
</div>
<!--*******************+  Fin de Modal Mesages *****************-->

<!-- Cargador loading -->
<ngx-ui-loader
  bgsColor="#80ba27"
  bgsOpacity="0.7"
  bgsPosition="bottom-right"
  bgsSize="60"
  bgsType="cube-grid"
  fgsType="cube-grid"
  fgsPosition="bottom-right"
  [fgsTemplate]="foregroundSpinner"
  [bgsTemplate]="backgroundSpinner"></ngx-ui-loader>

<ng-template #foregroundSpinner>
  <!-- Tu plantilla personalizada para el spinner de primer plano -->
  <app-loader></app-loader>
</ng-template>

<ng-template #backgroundSpinner>
  <!-- Tu plantilla personalizada para el spinner de fondo -->
  <app-loader></app-loader>
</ng-template>
