import { MaintStandard } from "../Interfaces/maint-standard";
import { SubAta } from "./subAta";
import { SystemType } from "./systemType";

export class Ata {

	/*1*/ 	public id: number;
	/*2*/ 	public ataName: string;
	/*3*/ 	public ataNumber: number;
	public systemTypeID: number;

	public systemType: SystemType;
	public subAta: SubAta[];

	public atacode?: number
	public standardid?: number
	public maintstandard?: MaintStandard

	constructor(a ?:any) {
		this.id = a ? a.id : null;
		this.ataName = a ? a.ataName : null;
		this.ataNumber = a ? a.ataNumber : null;
		this.systemTypeID = a ? a.systemTypeID : null;
		this.atacode = a ? a.atacode : null;
		this.standardid = a ? a.standardid : null;
		this.maintstandard = (a && a.maintstandard) ? a.maintstandard : null;
	}

}
