import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';


// Modulos Externos
import { BehaviorSubject } from 'rxjs';

// Services


// Models
import { User } from './../models/class/user';
import { Machine } from '../models/class/machine';
import { Router } from '@angular/router';
import { Command } from '../models/class/command';
import { SubUnit } from '../models/class/subUnit';
import { ModalMessage, TypeMsm } from '../models/Interfaces/modalMessage';
import { Service } from '../models/class/service';
import { TypeState } from '../models/class/typeState';
import { Unit } from '../models/class/unit';
import { PeopleService } from './people.service';
import { take } from 'rxjs/operators';
import { MensajesService } from './mensajes.service';
import { Fleet } from '../models/class/fleet';
import { Commandfleet } from '../models/class/commandfleet';
import { Countries } from '../models/class/countries';
import { Region } from '../models/class/region';
import { Departament } from '../models/class/departament';
import { Airport } from '../models/class/airport';
import { SpecialLocation } from '../models/class/speciallocations';
import { Maker } from '../models/class/maker';
import { FleetFamily } from '../models/class/fleetFamily';
import { ImageType } from '../models/class/imagetype';
import { DocumentType } from '../models/class/documenttype';
import { AssetStatus } from '../models/class/assetstatus';
import { AuthGoogleService } from './auth-google/auth-google.service';
import { isEqual } from 'lodash';
import { Controller } from '../models/class/controller';
import { RoleMachine } from '../models/class/roleMachine';
import { StateName } from '../models/class/stateName';
import { Formation } from '../models/class/formation';


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  static changeStateModalLoaderEmitter = new EventEmitter<boolean>();
  static changeStateModalMessageEmitter = new EventEmitter<ModalMessage>();
  static changeDataModalMessageEmitter = new EventEmitter<any>();
  static closeModalMessageEmitter = new EventEmitter<any>();
  static changeUserDataEmitter = new EventEmitter<any>();
  static resourcePortfolioEmitter = new EventEmitter<any>();
  private emitterCalendar$ = new Subject<any>();
  private receiverCalendar$ = new Subject<any>();


  private typeViewCards = new BehaviorSubject({ typeView: "" });
  sharedtypeViewCards = this.typeViewCards.asObservable();

  private widthMachineCards = new BehaviorSubject(false);
  sharedWidthMachineCards = this.widthMachineCards.asObservable();

  private indexIDMachineUpdated = new BehaviorSubject([]);
  sharedIDMachineUpdated = this.indexIDMachineUpdated.asObservable();

  private multiDailyHistMachinesSelected: Machine[] = []
  private BSMultiDailyHistSelected = new BehaviorSubject({ machines: Array<Machine>() });
  sharedMultiDailyHist = this.BSMultiDailyHistSelected.asObservable();


  private controlModalMultiDailyHist = new Subject<any>();
  sharedControlModalMultiDailyHist = this.controlModalMultiDailyHist.asObservable();

  private machineList = new BehaviorSubject([new Machine()]);
  sharedMachineList = this.machineList.asObservable();

  private serviceList = new BehaviorSubject([new Service()]);
  sharedServiceList = this.serviceList.asObservable();

  private filterByCallSing = new BehaviorSubject('');
  sharedFilterByCallSing = this.filterByCallSing.asObservable();
  private filterByYellowCard = new BehaviorSubject(false);
  sharedFilterByYellowCard = this.filterByYellowCard.asObservable();

  private styleByRemoteHover = new BehaviorSubject('');
  sharedStyleByRemoteHover = this.styleByRemoteHover.asObservable();

  private closeAllHistories = new BehaviorSubject(false);
  sharedCloseAllHistories = this.closeAllHistories.asObservable();

  public token: any;
  public typeView: any;
  public idUser: any;

  public today: string;

  public roles = {
    TECNICO: 1,
    PRODUCCION: 2,
    LIDER: 3,
    COMANDANTE: 7
  };

  public optionsMap: any = {
    center: {
      lat: 3.545967,
      lng: -73.175967
    },
    zoom: 6,
    disableDefaultUI: true,
    zoomControl: false,
    styles:
      [
        {
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#1d2c4d'
            }
          ]
        },
        {
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#8ec3b9'
            }
          ]
        },
        {
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'color': '#1a3646'
            }
          ]
        },
        {
          'featureType': 'administrative.country',
          'elementType': 'geometry.stroke',
          'stylers': [
            {
              'color': '#73fdff'
            }
          ]
        },
        {
          'featureType': 'administrative.country',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#d6d6d6'
            }
          ]
        },
        {
          'featureType': 'administrative.country',
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'administrative.land_parcel',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#a9a9a9'
            }
          ]
        },
        {
          'featureType': 'administrative.land_parcel',
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'administrative.locality',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#d6d6d6'
            }
          ]
        },
        {
          'featureType': 'administrative.locality',
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'administrative.province',
          'elementType': 'geometry.stroke',
          'stylers': [
            {
              'color': '#4b6878'
            }
          ]
        },
        {
          'featureType': 'administrative.province',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#d6d6d6'
            }
          ]
        },
        {
          'featureType': 'administrative.province',
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'landscape.man_made',
          'elementType': 'geometry.stroke',
          'stylers': [
            {
              'color': '#334e87'
            },
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'landscape.natural',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#023e58'
            }
          ]
        },
        {
          'featureType': 'poi',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#283d6a'
            }
          ]
        },
        {
          'featureType': 'poi',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#6f9ba5'
            }
          ]
        },
        {
          'featureType': 'poi',
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'color': '#1d2c4d'
            }
          ]
        },
        {
          'featureType': 'poi.park',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#023e58'
            }
          ]
        },
        {
          'featureType': 'poi.park',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#6f9ba5'
            }
          ]
        },
        {
          'featureType': 'poi.park',
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'road',
          'elementType': 'labels',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'labels',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'road',
          'elementType': 'geometry',
          'stylers': [
            {
              /*'visibility': 'off'*/
              'color': '#304a7d'
            }
          ]
        },
        {
          'featureType': 'road',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              /* 'visibility': 'off'*/
              'color': '#98a5be'
            }
          ]
        },
        {
          'featureType': 'road',
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'visibility': 'off'
              /* 'color': '#1d2c4d'*/
            }
          ]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#2c6675'
            }
          ]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'geometry.stroke',
          'stylers': [
            {
              'color': '#255763'
            }
          ]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#b0d5ce'
            }
          ]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'color': '#023e58'
            },
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'transit',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#98a5be'
            }
          ]
        },
        {
          'featureType': 'transit',
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'color': '#1d2c4d'
            }
          ]
        },
        {
          'featureType': 'transit.line',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#283d6a'
            }
          ]
        },
        {
          'featureType': 'transit.station',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#3a4762'
            }
          ]
        },
        {
          'featureType': 'water',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#141f37'
            }
          ]
        },
        {
          'featureType': 'water',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#4e6d70'
            }
          ]
        }
      ]
  };

  // Capturar el idioma por defecto de la web
  public defaultLanguage = window?.navigator.language?.substring(0, 2)

  //private userInfo: User;
  private allTypeStates: TypeState[];

  constructor(
    private _router: Router,
    private authGoogleService: AuthGoogleService,
  ) { }

  /*** Message Emiter */
  nextTypeViewCards(typeView: { typeView: string }) {
    this.typeViewCards.next(typeView)
    this.setTypeView(typeView.typeView)
  }

  nextBSmultiCardsSelected(cardSelected: Machine) {
    let pos = -1;
    pos = this.multiDailyHistMachinesSelected.findIndex(x => x.id == cardSelected.id)
    if (pos >= 0) this.multiDailyHistMachinesSelected.splice(pos, 1)
    else this.multiDailyHistMachinesSelected.push(cardSelected)
    this.BSMultiDailyHistSelected.next({ machines: this.multiDailyHistMachinesSelected })
  }
  deleteAllListBSmultiCardsSelected() {
    this.multiDailyHistMachinesSelected = [];
    this.BSMultiDailyHistSelected.next({ machines: this.multiDailyHistMachinesSelected });
  }
  selectAllListBSmultiCardsSelected() {
    this.multiDailyHistMachinesSelected = [];
    this.machineList.value.forEach(element => {
      this.multiDailyHistMachinesSelected.push(element);
    });
    this.BSMultiDailyHistSelected.next({ machines: this.multiDailyHistMachinesSelected });
  }
  updateMachieInMachineList(updatedMachine: Machine) {
    let newMachineList: Machine[] = this.machineList.value;
    let index: number = newMachineList.findIndex(element => element.id == updatedMachine.id);
    if (index >= 0) {
      //this.nextIdMachineUpdated(updatedMachine.id);
      newMachineList[index] = updatedMachine;
      this.nextMachineList(newMachineList);
    }
  }
  nextIdMachineUpdated(index: number[]) {
    this.indexIDMachineUpdated.next(index);
  }
  counter: number = 0;
  listIdCardsOpened: number[] = [];
  validateWidthMachineCards(show: boolean, idMachine: number) {
    let pos = -1;
    pos = this.listIdCardsOpened.findIndex(x => x == idMachine)
    if (pos >= 0) this.listIdCardsOpened.splice(pos, 1)
    else this.listIdCardsOpened.push(idMachine)
    this.nextIdMachineUpdated(this.listIdCardsOpened);
    if (show) {
      this.counter++;
      if (this.counter == 1) this.widthMachineCards.next(true);
    } else {
      this.counter--;
      if (this.counter == 0) this.widthMachineCards.next(false);
    }
  }
  closeAllmachineHistories() {
    this.closeAllHistories.next(true);
  }

  nextControlModalMultiDailyHist(modalInfo) {
    this.controlModalMultiDailyHist.next(modalInfo)
  }

  nextMachineList(machineList: Machine[]) {
    this.machineList.next(machineList)
  }
  nextServiceList(serviceList: Service[]) {
    this.serviceList.next(serviceList)
  }

  remoteFilterByCallSing(callSing: string) {
    this.filterByCallSing.next(callSing);
  }
  remoteFilterByYellowCard() {
    this.filterByYellowCard.next(true);
  }

  remoteOnHoverMachine(callSing: string) {
    this.styleByRemoteHover.next(callSing);
  }
  /*---------------------------------Inicio de Funciones Propias------------------*/


  getToken() {
    let token = localStorage.getItem('accessToken');
    this.token = null;
    if (token) {
      this.token = token;
    }
    return this.token;
  }

  setToken(token: any) {
    localStorage.setItem('accessToken', token);
  }

  getTokenTtl() {
    let ttl = localStorage.getItem('ttl');
    return ttl;
  }

  setTokenTtl(ttl: any) {
    localStorage.setItem('ttl', ttl);
  }

  getTokenCreated() {
    let date = localStorage.getItem('date');
    return date;
  }

  setTokenCreated(date: any) {
    localStorage.setItem('date', date);
  }

  getTypeView() {
    let typeView = localStorage.getItem('typeView');
    this.typeView = 'tripulante';
    if (typeView) {
      this.typeView = typeView;
    }
    return this.typeView;
  }

  setTypeView(typeView: any) {
    localStorage.setItem('typeView', typeView);
  }

  getUserId() {
    let idUser = localStorage.getItem('currentUser');
    this.idUser = null;
    if (idUser) {
      this.idUser = idUser;
    }
    return this.idUser;
  }

  setUserId(idUser: any) {
    localStorage.setItem('currentUser', JSON.stringify(idUser));
  }

  setOptionsMap(opt: any) {
    this.optionsMap = opt;
  }

  getOptionsMap(): any {
    return this.optionsMap;
  }

  logOutUser() {
    this.authGoogleService.logout()
    localStorage.removeItem('accessToken');
    localStorage.removeItem('currentUser');
    localStorage.clear();
  }

  /*---------------------------------Inicio Funciones Info Usuarios ------------------*/
  setUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  getUser(): User {
    return JSON.parse(localStorage.getItem('user')) //this.userInfo;
  }
  getCommand(): Command {
    return this.getUser().command;
  }
  getSubUnit(): SubUnit {
    return this.getUser().subUnit;
  }
  getUnits(): number[] {
    return this.getUser()?.role?.principals?.map(x => x.unitID); // Acceso a los roleMappings por rol de usuario
  }
  getRoles(): string[] {
    return this.getUser()?.roles?.map(x => x.name);
  }
  setAllTypeStates(typeStates: TypeState[]): void {
    this.allTypeStates = typeStates;
  }
  getAllTypeStates(): TypeState[] {
    return this.allTypeStates;
  }
  setMenuSelect(item: any) {
    localStorage.setItem('menuSelect', JSON.stringify(item));
  }
  getMenuSelect(): any {
    return JSON.parse(localStorage.getItem('menuSelect'));
  }
  // setUnitsList(item: Unit[]) {
  //   localStorage.setItem('units', JSON.stringify(item));
  // }
  // getUnitsList(): Unit[] {
  //   return JSON.parse(localStorage.getItem('units'));
  // }
  setMachinesList(item: Machine[]) {
    localStorage.setItem('machines', JSON.stringify(item));
  }
  getMachinesList(): Machine[] {
    return JSON.parse(localStorage.getItem('machines'));
  }
  setAssetStatus(item: AssetStatus[]) {
    localStorage.setItem('AssetStatus', JSON.stringify(item));
  }
  getAssetStatus(): AssetStatus[] {
    return JSON.parse(localStorage.getItem('AssetStatus'));
  }
  // setFleetsList(item: Fleet[]) {
  //   localStorage.setItem('fleets', JSON.stringify(item));
  // }
  // getFleetsList(): Fleet[] {
  //   return JSON.parse(localStorage.getItem('fleets'));
  // }
  // setCommandFleetsList(item: Commandfleet[]) {
  //   localStorage.setItem('commandfleets', JSON.stringify(item));
  // }
  // getCommandFleetsList(): number[] {
  //   return JSON.parse(localStorage.getItem('commandfleets')).map(x => x.fleetID);
  // }
  setAccess(item: any) {
    localStorage.setItem('access', JSON.stringify(item));
  }
  getAccess(): any {
    return JSON.parse(localStorage.getItem('access'));
  }
  // setCommandDetail(item: Command) {
  //   localStorage.setItem('commandDetail', JSON.stringify(item));
  // }
  // getCommandDetail(): Command {
  //   return JSON.parse(localStorage.getItem('commandDetail'));
  // }
  setCountries(item: Countries[]) {
    localStorage.setItem('countries', JSON.stringify(item));
  }
  getCountries(): Countries[] {
    return JSON.parse(localStorage.getItem('countries'));
  }
  setRegions(item: Region[]) {
    localStorage.setItem('regions', JSON.stringify(item));
  }
  getRegions(): Region[] {
    return JSON.parse(localStorage.getItem('regions'));
  }
  setDeparments(item: Departament[]) {
    localStorage.setItem('deparments', JSON.stringify(item));
  }
  getDeparments(): Departament[] {
    return JSON.parse(localStorage.getItem('deparments'));
  }
  setAirports(item: Airport[]) {
    localStorage.setItem('airports', JSON.stringify(item));
  }
  getAirports(): Airport[] {
    return JSON.parse(localStorage.getItem('airports'));
  }
  setSpecialLocations(item: SpecialLocation[]) {
    localStorage.setItem('specialLocations', JSON.stringify(item));
  }
  getSpecialLocations(): SpecialLocation[] {
    return JSON.parse(localStorage.getItem('specialLocations'));
  }
  // setManufacture(item: Maker[]) {
  //   localStorage.setItem('manufacture', JSON.stringify(item));
  // }
  // getManufacture(): Maker[] {
  //   return JSON.parse(localStorage.getItem('manufacture'));
  // }
  setShowFilter(item: boolean) {
    localStorage.setItem('showFilter', JSON.stringify(item));
  }
  getShowFilter(): boolean {
    return JSON.parse(localStorage.getItem('showFilter'));
  }
  setUpdate(item: boolean) {
    localStorage.setItem('update', JSON.stringify(item));
  }
  getUpdate(): boolean {
    return JSON.parse(localStorage.getItem('update'));
  }
  setFilterMultipleDate(item: boolean) {
    localStorage.setItem('filtermultipledate', JSON.stringify(item));
  }
  getFilterMultipleDate(): boolean {
    return JSON.parse(localStorage.getItem('filtermultipledate'));
  }
  setFilterTotal(item: number) {
    localStorage.setItem('filtertotal', JSON.stringify(item));
  }
  getFilterTotal(): number {
    return JSON.parse(localStorage.getItem('filtertotal'));
  }
  setFleetFamilys(item: FleetFamily[]) {
    localStorage.setItem('fleetfamilys', JSON.stringify(item));
  }
  getFleetFamilys(): FleetFamily[] {
    return JSON.parse(localStorage.getItem('fleetfamilys'));
  }
  setImageTypes(item: ImageType[]) {
    localStorage.setItem('ImageType', JSON.stringify(item));
  }
  getImageTypes(): ImageType[] {
    return JSON.parse(localStorage.getItem('ImageType'));
  }
  setDocumentTypes(item: DocumentType[]) {
    localStorage.setItem('DocumentType', JSON.stringify(item));
  }
  getDocumentTypes(): DocumentType[] {
    return JSON.parse(localStorage.getItem('DocumentType'));
  }
  setMyMenu(item: any) {
    localStorage.setItem('menu__', JSON.stringify(item));
  }
  getMyMenu(): any[] {
    return JSON.parse(localStorage.getItem('menu__'));
  }
  setTypeAuth(item: string) {
    localStorage.setItem('typeAuth', JSON.stringify(item));
  }
  getTypeAuth(): string {
    return JSON.parse(localStorage.getItem('typeAuth'));
  }
  setVisibleOptions(item: any) {
    localStorage.setItem('VisibleOptions', JSON.stringify(item));
  }
  getVisibleOptions(): any {
    return JSON.parse(localStorage.getItem('VisibleOptions'));
  }
  /*---------------------------------Fin Funcion Usuario  ------------------*/



  /*************************** Funciones generales *************************/
  verifyOnlyLetters(text: string): boolean {
    let part: any = /^[a-zA-Z\u00C0-\u00ff]+$/;
    return part.test(text);
  }

  verifyOnlyCharacters(text: string): boolean {
    let part: any = /^[\u00C0-\u00ff\w\'\.\,\'\:\;\-\!\s]+$/;
    return part.test(text);
  }

  verifyRole(u: User): any {
    if (u.roleMappings && u.roleMappings[0]) {
      return;
    }
    // TODO: verificar funcion
    this.changeStateModalMessage({ typeMsm: TypeMsm.success, titleMsm: 'Error!', textMsm: 'El usuario no tiene un rol.', textFooter: 'Para obtener más información, comuníquese con su administrador de sistema.' });
    // setTimeout(() => this._router.navigate(['/home']), 1000);
  }

  customDate(year: number, month: any, day: any): string {
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    return `${year}-${month}-${day}`;
  }

  getExtensionFile(filename): any {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
  }

  /*************************** Funciones Emitter ****************/
  changeStateModalLoader(data: boolean): void {
    SharedService.changeStateModalLoaderEmitter.emit(data);
  }

  changeStateModalMessage(data: ModalMessage): void {
    SharedService.changeStateModalMessageEmitter.emit(data);
  }

  closeModalMessage(): void {
    SharedService.closeModalMessageEmitter.emit();
  }

  changeDataModalMessage(data: any): void {
    SharedService.changeDataModalMessageEmitter.emit(data);
  }

  changeUserData(data: any): void {
    SharedService.changeUserDataEmitter.emit(data);
  }

  changeStatusResourcePortfolio(data: any): void {
    SharedService.resourcePortfolioEmitter.emit(data);
  }

  emitterCalendarDate(value: any): void {
    this.emitterCalendar$.next(value);
  }

  getValueCalendarDate(): Observable<any> {
    return this.emitterCalendar$.asObservable();
  }

  receiverCalendarDate(value: any): void {
    this.receiverCalendar$.next(value);
  }

  getValueReceiverCalendarDate(): Observable<any> {
    return this.receiverCalendar$.asObservable();
  }

  toNumberFixed(number: any) {
    return Number(number).toFixed(1)
  }

  /*************************** Funciones Para Cookies ****************/
  getCookie(c_name) {
    var c_value = document.cookie;
    var c_start = c_value.indexOf(' ' + c_name + '=');
    if (c_start == -1) {
      c_start = c_value.indexOf(c_name + '=');
    }
    if (c_start == -1) {
      c_value = null;
    }
    else {
      c_start = c_value.indexOf('=', c_start) + 1;
      var c_end = c_value.indexOf(';', c_start);
      if (c_end == -1) {
        c_end = c_value.length;
      }
      c_value = unescape(c_value.substring(c_start, c_end));
    }
    return c_value;
  }

  setCookie(c_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays == null) ? '' : '; expires=' + exdate.toUTCString());
    document.cookie = c_name + '=' + c_value;
  }

  delcookie(name) {
    this.setCookie(name, null, -100);
  }

  /************* Filtros de consultas  */
  // setDateSelect(item: string) {
  //   localStorage.setItem('DateSelect', JSON.stringify(item));
  // }

  setDateSelect(item: string[]) {
    localStorage.setItem('DateSelect', JSON.stringify(item));
  }

  getDateSelect(): string[] {
    return JSON.parse(localStorage.getItem('DateSelect'));
  }

  getFormationsSelect(): Formation[] {
    return JSON.parse(localStorage.getItem('FormationsSelect'));
  }

  setFormationsSelect(item: Formation[]) {
    localStorage.setItem('FormationsSelect', JSON.stringify(item));
  }
  
  getUnitsSelectAdmin(): Unit[] {
    return JSON.parse(localStorage.getItem('UnitsSelectAdmin'));
  }

  setUnitsSelectAdmin(item: Unit[]) {
    localStorage.setItem('UnitsSelectAdmin', JSON.stringify(item));
  }

  setUnitsSelectOperativas(item: SubUnit[]) {
    localStorage.setItem('UnitsSelect', JSON.stringify(item));
  }

  getUnitsSelectOperativas(): SubUnit[] {
    return JSON.parse(localStorage.getItem('UnitsSelect'));
  }

  setMachinesSelect(item: Machine[]) {
    localStorage.setItem('MachinesSelect', JSON.stringify(item));
  }

  getMachinesSelect(): Machine[] {
    return JSON.parse(localStorage.getItem('MachinesSelect'));
  }

  setFleetsSelect(item: Fleet[]) {
    localStorage.setItem('FleetsSelect', JSON.stringify(item));
  }

  getFleetsSelect(): Fleet[] {
    return JSON.parse(localStorage.getItem('FleetsSelect'));
  }

  setControllersSelect(item: Controller[]) {
    localStorage.setItem('ControllersSelect', JSON.stringify(item));
  }

  getControllersSelect(): Controller[] {
    return JSON.parse(localStorage.getItem('ControllersSelect'));
  }

  setRoleMachinesSelect(item: RoleMachine[]) {
    localStorage.setItem('RoleMachinesSelect', JSON.stringify(item));
  }

  getRoleMachinesSelect(): RoleMachine[] {
    return JSON.parse(localStorage.getItem('RoleMachinesSelect'));
  }

  setFamilyFleetSelect(item: FleetFamily[]) {
    localStorage.setItem('FamilyFleetSelect', JSON.stringify(item));
  }

  getFamilyFleetSelect(): FleetFamily[] {
    return JSON.parse(localStorage.getItem('FamilyFleetSelect'));
  }

  setStateNameSelect(item: StateName[]) {
    localStorage.setItem('StateNameSelect', JSON.stringify(item));
  }

  getStateNameSelect(): StateName[] {
    return JSON.parse(localStorage.getItem('StateNameSelect'));
  }

  setTypeStateSelect(item: TypeState[]) {
    localStorage.setItem('TypeStateSelect', JSON.stringify(item));
  }

  getTypeStateSelect(): TypeState[] {
    return JSON.parse(localStorage.getItem('TypeStateSelect'));
  }

  private monitorSelectChange = new BehaviorSubject<any | null>(null);
  changeSelect$ = this.monitorSelectChange.asObservable();

  setMonitorSelectChange(machinesSelect: any[], unitAdminSelect: any[], fleets_models: any[], listDate: any[], controllerSelect: any[], unitOpSelect: any[],
    formationsSelect: any[], roleMachinesSelect: any[], familyFleetSelect: any[], stateNameSelect: any[], typeState: any[]
  ): void {
    const newState: any = { machinesSelect, unitAdminSelect, fleets_models, listDate, controllerSelect, unitOpSelect,
      formationsSelect, roleMachinesSelect, familyFleetSelect, stateNameSelect, typeState
     };
    const currentState = this.monitorSelectChange.value;

    if (!isEqual(newState, currentState)) {
      this.monitorSelectChange.next(newState);
    }
  }

  getMonitorSelectChange(): any | null {
    return this.monitorSelectChange.value;
  }

}
