// import { Injectable } from '@angular/core';
// import {
// 	HttpRequest,
// 	HttpHandler,
// 	HttpEvent,
// 	HttpInterceptor
// } from '@angular/common/http';
// import { Observable, of } from 'rxjs';
// import { NgxUiLoaderService } from 'ngx-ui-loader';
// import { catchError, finalize } from 'rxjs/operators';

// @Injectable()
// export class ApiInterceptor implements HttpInterceptor {

// 	private _activeRequest = 0;

// 	constructor(private _ngxUiLoaderService: NgxUiLoaderService) { }

// 	// intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
// 	// 	if (this._activeRequest === 0) {
// 	// 		this._ngxUiLoaderService.startBackground('do-background-things');
// 	// 		//this._ngxUiLoaderService.start();
// 	// 	}
// 	// 	this._activeRequest++;
// 	// 	return next.handle(request).pipe(finalize(() => this._stopLoader()));
// 	// }

// 	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
// 		if (this._activeRequest === 0) {
// 		  this._ngxUiLoaderService.startBackground('do-background-things');
// 		}
// 		this._activeRequest++;
	  
// 		return next.handle(request).pipe(
// 		  catchError((error) => {
// 			// Aquí puedes manejar el error de la solicitud si lo deseas
// 			return of(error); // Devolver un error vacío o el error mismo
// 		  }),
// 		  finalize(() => this._stopLoader())
// 		);
// 	  }

// 	private _stopLoader() {
// 		this._activeRequest--;
// 		if (this._activeRequest === 0) {
// 			this._ngxUiLoaderService.stopBackground('do-background-things');
// 			//this._ngxUiLoaderService.stop();
// 		}
// 	}
// }


import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from 'src/app/services/shared.service';
import { AuthUserService } from 'src/app/services/auth-users/auth-users-service.service';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private _activeRequest = 0;
  private cancelSubject: Subject<any> = new Subject();

  constructor(
    private _ngxUiLoaderService: NgxUiLoaderService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this._activeRequest === 0) {
      this._ngxUiLoaderService.startBackground('do-background-things');
    }

    this._activeRequest++;

    // Pasar el Subject de cancelación a la solicitud
    return next.handle(request).pipe(
      finalize(() => this._stopLoader())
    );
  }

  // Método para cancelar la solicitud
  cancelRequest(): void {
    this.cancelSubject.next();
  }

  private _stopLoader() {
    this._activeRequest--;
    if (this._activeRequest === 0) {
      this._ngxUiLoaderService.stopBackground('do-background-things');
    }
  }

  private isTokenExpired(token: string): boolean {
    const payload = this.decodeJWT(token);
    if (!payload) {
      return true;
    }

    const expirationDate = payload.exp; // Asumiendo que el JWT contiene un campo "exp" que indica la expiración en formato UNIX
    if (!expirationDate) {
      return true;
    }

    const now = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
    return now >= expirationDate; // Si el tiempo actual es mayor o igual a la fecha de expiración, el token ha caducado
  }

  private decodeJWT(token: string): any {
    try {
      const parts = token.split('.');
      if (parts.length !== 3) {
        return null;
      }

      const payload = parts[1];
      const decoded = atob(payload); // Decodificar la parte del payload del JWT
      return JSON.parse(decoded); // Retornar el objeto decodificado
    } catch (error) {
      return null;
    }
  }
}

