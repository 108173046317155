<div class="loader">
  <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.94 64.06">
    <!-- Grupo de elementos animados (círculos y línea) -->
    <g class="rotate">
      <g id="Group_27">
        <path
          id="Path_83"
          class="cls-1"
          d="M44.79,20.09l-1.8,1.09c1.93,3.28,2.96,7.08,2.9,10.88,0,12.03-9.78,21.82-21.82,21.82S2.25,44.09,2.25,32.06,12.03,10.24,24.07,10.24c2.51,0,5.02.45,7.4,1.29l.71-1.99c-2.64-.97-5.34-1.42-8.17-1.48C10.75,8.06,0,18.74,0,32s10.68,24,23.94,24,24-10.68,24-23.94h0c0-4.18-1.09-8.3-3.15-11.97Z" />
      </g>
      <g id="Group_32">
        <path
          id="Path_88"
          class="cls-1"
          d="M36.36,24.92c3.86,6.82,1.48,15.44-5.34,19.31-6.82,3.86-15.44,1.48-19.31-5.34-3.86-6.82-1.48-15.44,5.34-19.31,3.6-2.06,7.92-2.38,11.78-.97l.71-1.99c-1.8-.64-3.67-.97-5.6-.97-9.07,0-16.35,7.34-16.35,16.41s7.34,16.35,16.41,16.35,16.35-7.34,16.35-16.41c0-2.83-.77-5.66-2.19-8.17l-1.8,1.09Z" />
      </g>
      <g id="Group_21-2">
        <path
          id="Path_79"
          class="cls-1"
          d="M23.97,32.03l2.32-6.37c-.77-.26-1.54-.39-2.32-.45-3.73,0-6.82,3.02-6.82,6.82s3.02,6.82,6.82,6.82,6.82-3.02,6.82-6.82c0-1.22-.32-2.38-.9-3.41l-5.92,3.41Z" />
      </g>
      <rect
        class="cls-2"
        x="23.33"
        y="-1.83"
        width="1.29"
        height="67.73"
        transform="translate(12.45 -6.28) rotate(20.07)" />
    </g>
    <!-- Puntos estáticos -->
    <g id="Group_28">
      <path
        id="Path_84"
        class="cls-3"
        d="M41.82,31.85c0,1.29-1.03,2.38-2.38,2.38s-2.38-1.03-2.38-2.32v-.06c0-1.29,1.03-2.38,2.38-2.38s2.38,1.03,2.38,2.32v.06Z" />
    </g>
    <g id="Group_29">
      <path
        id="Path_85"
        class="cls-3"
        d="M12.28,49.6c0,1.29-1.03,2.38-2.38,2.38s-2.38-1.03-2.38-2.32v-.06c0-1.29,1.03-2.38,2.38-2.38s2.38,1.09,2.38,2.38Z" />
    </g>
    <g id="Group_30">
      <path
        id="Path_86"
        class="cls-3"
        d="M45.45,44.85c0,1.29-1.03,2.38-2.38,2.38s-2.38-1.03-2.38-2.38,1.03-2.38,2.38-2.38,2.38,1.03,2.38,2.38Z" />
    </g>
    <g id="Group_31">
      <path
        id="Path_87"
        class="cls-3"
        d="M25.77,9.06c0,1.29-1.03,2.38-2.38,2.38s-2.38-1.03-2.38-2.38,1.03-2.38,2.38-2.38,2.38,1.03,2.38,2.38Z" />
    </g>
    <g id="Group_33">
      <path
        id="Path_89"
        class="cls-3"
        d="M11.14,31.85c0,1.29-1.03,2.38-2.38,2.38s-2.38-1.03-2.38-2.32v-.06c0-1.29,1.03-2.38,2.38-2.38s2.38,1.03,2.38,2.32v.06Z" />
    </g>
  </svg>
</div>
