import { catchError, map, timeout } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { SharedService } from "./shared.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

// Environments
import { environment } from "./../../environments/environment";
import { MensajesService } from "./mensajes.service";
import { throwError } from "rxjs";
import { AuthUserService } from "./auth-users/auth-users-service.service";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  public urlHttp: string = environment.baseUrl 
  private controller = new AbortController()
  // private refreshTokenPromise: Promise<any> | null = null;

  constructor(
    private http: HttpClient,
    private _sharedService: SharedService,
    private _alert: MensajesService,
    // private _authUserService: AuthUserService,
  ) { 
    
  }

  // async validarTokens(): Promise<boolean> {
  //   const now = Math.floor(Date.now() / 1000); // Tiempo en segundos
  //   const remainingSeconds = Number(this._sharedService.getTokenTtl()) - now; // Restante en segundos
  //   const hours = Math.floor(remainingSeconds / 3600);
  
  //   if (remainingSeconds > 0 && remainingSeconds <= 300 || remainingSeconds < 0) {
  //     try {
  //       // Iniciar el proceso de refresco de token
  //       this.refreshTokenPromise = this._authUserService.refleshToken(
  //         this._sharedService.getToken(),
  //         hours < 0 ? Math.abs(hours) : 1
  //       );
  
  //       const result = await this.refreshTokenPromise;
  
  //       if (result?.ttl) {
  //         this._sharedService.setTokenTtl(result.ttl);
  //         return true;
  //       } else {
  //         this._sharedService.logOutUser();
  //         return false;
  //       }
  //     } catch (error) {
  //       this._sharedService.logOutUser();
  //       return false;
  //     } finally {
  //       this.refreshTokenPromise = null;
  //     }
  //   }  
  //   return true;
  // }  

  getHeader(): HttpHeaders {
    const token = this._sharedService.getToken()

    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    if (token) {
      headers = new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": token,
      });
    }

    return headers;
  }

  httpGet(url: string): any {
    let result = this.http.get(this.urlHttp + url, {
      headers: this.getHeader()
    }).pipe(timeout(this._alert._timeOut), catchError((err: any) => {    
      return throwError(err);
    }))
    return result
  }

  httpGetToken(url: string, timeOut: number): any {
    let result = this.http.get(this.urlHttp + url, {
      headers: this.getHeader()
    }).pipe(timeout(timeOut != undefined ? timeOut : this._alert._timeOut), catchError((err: any) => {
      return throwError(err);
    }))
    return result
  }

  httpPost(url: string, data: any): any {
    let result = this.http.post(this.urlHttp + url, data, {
      headers: this.getHeader(),
    }).pipe(timeout(this._alert._timeOut), catchError((err: any) => {
      return throwError(err);
    }))
    return result
  }

  httpDelete(url: string): any {
    let result = this.http.delete(this.urlHttp + url, {
      headers: this.getHeader()
    }).pipe(timeout(this._alert._timeOut), catchError((err: any) => {
      return throwError(err);
    }))
    return result
  }

  httpPut(url: string, data: any): any {
    let result = this.http.put(this.urlHttp + url, data, {
      headers: this.getHeader(),
    }).pipe(timeout(this._alert._timeOut), catchError((err: any) => {
      return throwError(err);
    }))
    return result
  }

  httpPatch(url: string, data: any): any {
    let result = this.http.patch(this.urlHttp + url, data, {
      headers: this.getHeader(),
    }).pipe(timeout(this._alert._timeOut), catchError((err: any) => {
      return throwError(err);
    }))
    return result
  }

  httpGetStorage(url: string): any {
    return this.http.get(url);
  }

  sendFile(file: any, url: string) {
    const token = this._sharedService.getToken()
    
    const urlHttp = this.urlHttp
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      const fd = new FormData();
      xhr.open("POST", urlHttp + url, true);
      // Agregar el encabezado de autorización
      xhr.setRequestHeader("Authorization", token);
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200)
          resolve(JSON.parse(xhr.responseText));
      };
      fd.append("file", file);
      xhr.send(fd);
    });
  }

  deleteFile(url: string) {
    const token = this._sharedService.getToken()

    const urlHttp = this.urlHttp
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      const fd = new FormData();
      xhr.open("DELETE", urlHttp + url, true);
      xhr.setRequestHeader("Authorization", token);
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200)
          resolve(JSON.parse(xhr.responseText));
      };
      //fd.append("file", file);
      xhr.send(fd);
    });
  }

  getHeaderChatBot(): HttpHeaders {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "ACTION_SECRET": "Nhk03CBnel"
    });

    return headers;
  }

  httpPostChatBot(data: any): any {
    return this.http.post("https://us-central1-oncobot.cloudfunctions.net/dev-medicalProcessesChatBot/ask", data, {
      headers: this.getHeaderChatBot(),
    });
  }

  cancelRequest(){
    this.controller.abort()
  }
}
