import { Component, Input, OnInit } from '@angular/core';
import { UseHistoryAugmented } from 'src/app/models/class/useHistoryAugmented';
import { SortPipe } from 'src/app/pipes/sort.pipe';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SharedService } from 'src/app/services/shared.service';
import * as moment from "moment";
import { ControllerUsed } from 'src/app/models/class/controllerUsed';

@Component({
  selector: 'new-app-status-annotations',
  templateUrl: './new-status-annotations.component.html',
  styleUrls: ['./new-status-annotations.component.sass']
})
export class NewStatusAnnotationsComponent implements OnInit {

  @Input() thisUseHistoryAugmented: ControllerUsed[];
  @Input() changeStyle: boolean = false;
  @Input() changeStyleTableView: boolean = false;

  public dateSelectFilter: string[] = []

  public dateRange = {
    startDay: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
    endDay: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
    firtDayI: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
    finishDayF: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString(),
    dayNow: new Date(new Date().setHours(0, 0, 0, 0)).toISOString().split("T"),
  };

  public endUse: ControllerUsed

  constructor(private _sortPipe: SortPipe,
    public _alert: MensajesService,
    private _sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.dateSelectFilter = (this._sharedService.getDateSelect() != undefined ? this._sharedService.getDateSelect().length > 0 ? this._sharedService.getDateSelect() : [this.dateRange.dayNow[0] + "T" + moment(new Date()).format("HH:mm:ss")] : [this.dateRange.dayNow[0] + "T" + moment(new Date()).format("HH:mm:ss")])
    
    if(this.thisUseHistoryAugmented){
      this.endUse = this.thisUseHistoryAugmented[0] //this.getUsedDay(this.thisUseHistoryAugmented)[0]
    }
  }

  // getUsedDay(_useHistoryAugmenteds: ControllerUsed[]) {
  //   let tempo_data = _useHistoryAugmenteds.filter(item => {
  //     return this.compareDate(this._alert.date_without_UTC(item.datereport, "DD MMMM YYYY"), this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "DD MMMM YYYY")) == -1
  //   })
  //   return tempo_data;
  // }

  // compareDate(dateTimeA: string, dateTimeB: string) {
  //   let value = 0 // A y B Es igual
  //   if (dateTimeA > dateTimeB) { // A es mayor
  //     value = 1
  //   }
  //   else if (dateTimeA <= dateTimeB) { // B es menor o igual
  //     value = -1
  //   }
  //   return value;
  // }

  // Función de ordenamiento de arreglos
  // private sortBy(arrayData, orderType: 'asc' | 'desc', sortKey: string) {
  //   return this._sortPipe.transform(arrayData, orderType, sortKey);
  // }
}
