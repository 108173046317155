import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Machine } from "../../../models/class/machine";
import { Router } from "@angular/router";
// Services
import { MachineService } from "../../../services/machine.service";
import { Service } from "../../../models/class/service";
import { take } from "rxjs/operators";
import { ServiceService } from "../../../services/service.service";
import { MaintHistoryService } from "../../../services/mainthistory.service";
import { ComponentAircraft } from "../../../models/class/component";
import { SharedService } from "../../../services/shared.service";
import { SystemType } from "src/app/models/class/systemType";
import { MaintHistory } from "src/app/models/class/maintHistory";
import { TypeMsm } from "src/app/models/Interfaces/modalMessage";
import { ControllerUsed } from "src/app/models/class/controllerUsed";
import { element, promise } from "protractor";
import { MaintcmpActivity } from "src/app/models/class/MaintcmpActivity";
import { Controller } from "src/app/models/class/controller";
import { Frecuences } from "src/app/models/class/frecuences";
import { MaintcmpActivitiesService } from "src/app/services/g-services/maintcmpactivities.service";

declare var require: any;
var async = require("async");

@Component({
  selector: "new-app-modal-maintenance",
  templateUrl: "./new-maintenance.component.html",
  styleUrls: ["./../modals.sass", "./new-maintenance.component.sass"],
})
export class NewMaintenanceComponent implements OnInit {
  //output
  @Output() stateModalMaintenance = new EventEmitter<boolean>();
  @Output() stateModalEditMaintenance = new EventEmitter<{ isOk: boolean, dataService: {} }>();

  // output
  @Output() loaderOutput = new EventEmitter<boolean>();

  @Output() onShowModalResources = new EventEmitter<MaintHistory>();

  @Input() machineSelectedID: number;
  @Input() isToEditService: boolean = false;
  @Input() listServicesSelecteds: number[] = [];

  @Input() machines: Machine[] = [];

  public loader = true;
  public machineModal: Machine;
  public historyMaint: any[] = []; // TODO Crear el tipo de variable
  public servicesComponent: Service[] = [];
  public service: Service[] = [];
  public services: MaintcmpActivity[] = [];
  public machineComponents: ComponentAircraft[] = [];
  public serviceTabs = [{ name: 'TASKCARD', id: 1 },
  { name: 'TBO', id: 2 },
  { name: 'HARDTIME', id: 3 },
  { name: 'PUBLICACIONES TECNICAS', id: 5 },
  { name: 'ESPECIALES', id: 6 },
  { name: 'ANOTACIONES DIFERIDAS', id: 4 }];
  public serviceTab: number;
  public systemTypes: SystemType[] = [];

  public unitTBOSelected: {
    title: string;
    modelName: string;
    machineName: string;
    currentName: string;
  };
  public unitTBO: {
    title: string;
    modelName: string;
    machineName: string;
    currentName: string;
  }[] = [
      {
        title: "HORAS",
        modelName: "frequencyHoursUse",
        machineName: "hoursUseStart",
        currentName: "totalHoursUse",
      },
      {
        title: "DIAS",
        modelName: "frequencyDaysUse",
        machineName: "daysUseStart",
        currentName: "totalDayUse",
      },
      {
        title: "CICLOS",
        modelName: "frequencyCycleUse",
        machineName: "cycleUseStart",
        currentName: "totalCycleUse",
      },
    ];

  public servicesModalSelected: {
    machineID?: number;
    maintCmpActivityID?: number;
    systemTypeID: number;
    maintCmpActivity?: {};
  }[] = [];

  public totalHours: number = 0
  public listaTotalController: Controller[] = []

  constructor(
    private _router: Router,
    private _machineService: MachineService,
    //private _serviceService: ServiceService,
    private _servicesService: MaintcmpActivitiesService,
    private _sharedService: SharedService,
    private _maintHistoryService: MaintHistoryService
  ) {
    this.unitTBOSelected = this.unitTBO[0];
    this.serviceTab = this.serviceTabs[0].id;
  }

  ngOnInit() {
    this.changeStateModalMaintenance();
    this.getAllSystemTypes();
    if (this.isToEditService && this.listServicesSelecteds.length > 0) {
      this.listServicesSelecteds.forEach(element => {
        this.selectMaintCmpActivity(this.machineSelectedID, element, null)
      });
    }
  }

  getAllSystemTypes(): void {
    this._machineService.getAllSystem().pipe(take(1)).subscribe(data => {
      this.systemTypes = data;
      this.systemTypes.forEach(element => {
        element.showSystem = false;
      });
    });
  }

  goToUrlWithData(url: string, data: any): void {
    this._router.navigate([url], {
      state: { services: data, machine: this.machineModal },
    });
  }

  filterServicesBySystemTypeID(systemTypeID: number, serviceTypeID: number): Service[] {
    // console.log (this.servicesComponent.filter(x => x.systemTypeID === systemTypeID))
    return this.servicesComponent.filter(
      // (x) => x.systemTypeID === systemTypeID && x.serviceTypeID === serviceTypeID
      (x) => x.systemTypeID === systemTypeID
    );
  }

  closeModal() {
    if (this.isToEditService) this.stateModalEditMaintenance.emit({ isOk: false, dataService: {} });
    else this.stateModalMaintenance.emit(false);
  }

  changeStateModalMaintenance(): void {
    this.historyMaint = [];
    this.servicesComponent = [];

    let filter = {
      where: { "id": this.machineSelectedID },
      include: [
        {
          relation: "dailyUseHistories", "scope": { "order": "id DESC", "limit": 3 }
        },
        {
          relation: "useHistoryAugmenteds", "scope": { "order": "id DESC", "limit": 3 }
        },
        "typeState", "fleet", "unit", "airport",
        {
          relation: "controlleruse", "scope": {
            where: { "controllerid": 1 }, "order": "datereport DESC", "limit": 1,
            include: [
              "departament", "airport", "speciallocation", "typeState"
            ]
          }
        }
      ]
    }

    let tempoMachine = this.machines.find(d => d.id == this.machineSelectedID)
    this.totalHours = this.sumHours(tempoMachine?.controlleruse) + Number(tempoMachine.currentHoursUse) * 1

    // SE SOLICITA TODA LA INFORMACION DE LA MAQUINA
    this._machineService
      .get(JSON.stringify(filter))
      .pipe(take(1))
      .subscribe(
        (mydata) => {
          this.machineModal = tempoMachine //info[0]
          this.machineModal.totalDayUse = 0

          // SE SOLICITA TODOS LOS SERVICIOS PARA LA MAQUINA
          var pos = -1;

          let filter = {
            where: {
              "commandid": this.machineModal.commandID,
              "servicestateId": 2 // Programada para asignar
            },
            include: [
              {
                relation: "frecuences", scope: {
                  include: [
                    { relation: "controller" }
                  ]
                }
              }
            ]
          }
          this._servicesService
            //.getAllServicesByFleetID(this.machineModal.fleetID)
            .get(JSON.stringify(filter))
            .pipe(take(1))
            .subscribe((dataServ) => {
              this.services = dataServ;

              let tempoListaTotalFrecuences = this.services.map(d => d.frecuences)
              tempoListaTotalFrecuences.forEach((element) => {
                element.map(controller => {
                  let find = (this.listaTotalController?.find(x => x?.id == controller.controller.id))
                  if (!find) {
                    this.listaTotalController.push(controller.controller)
                  }
                  return find
                })
              })

              // ASYNC PARA CARGAR LOS ULTIMOS SERVICIOS DE CADA UNA DE LAS MAQUINAS DE LA FLOTA SELECICONADA
              var cx = this;
              cx.loader = false;

              // recorro las maquinas en async
              async.forEachLimit(
                cx.services,
                5,
                function (service, cb) {
                  // consulto el ultimo maintHistory por machineID y por maintCmpActivityID (service)
                  cx._maintHistoryService
                    .getLastMaintHistoryByMachineIDandServiceID(
                      cx.machineModal.id,
                      service.id,
                      1
                    )
                    .pipe(take(1))
                    .subscribe((dataMaint) => {
                      if (dataMaint.length >= 1) {
                        const dataMAintMap = dataMaint.map(
                          d => {
                            d.daysUseStart = (new Date(d.dateFinish).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);
                            return d
                          }
                        )
                        cx.historyMaint = cx.historyMaint.concat(
                          dataMAintMap
                        );
                      }
                      cb();
                    });
                },
                (err: any) => {
                  cx.loaderOutput.emit(false);
                  cx.loader = false;
                }
              );
            });

          //  SE SOLICITAN TODOS LOS COMPONENTES DE LA MAQUINA Y LOS SERVICIOS DE CADA COMPONENTE
          this._machineService
            .getComponentsByMachineID(this.machineModal.id)
            .pipe(take(1))
            .subscribe((data) => {
              this.machineComponents = data;
              // Exatrer los ID de tipos de sistemas (no repetidos)
              let unicos = this.getUnique(
                this.machineComponents,
                "systemTypeID"
              ).map((x) => x.systemTypeID);
              // console.log("unicos", unicos)
              unicos.forEach((element) => {
                let filter = `{ "where" : { "systemTypeID": ${element} , "fleetID" : ${this.machineModal.fleetID} }, "include":["systemType", "ataType","maintResources"] }`;

                // this._serviceService
                //   .getAllServices(filter)
                //   .pipe(take(1))
                //   .subscribe((data) => {
                //     // console.log("data", data)
                //     this.servicesComponent = this.servicesComponent.concat(
                //       data
                //     );

                //     // TODO: Refactorizar asyn func
                //     // ASYNC PARA CARGAR LOS ULTIMOS SERVICIOS DE CADA UNA DE LAS MAQUINAS DE LA FLOTA SELECICONADA
                //     var cx = this;
                //     async.forEachLimit(
                //       cx.servicesComponent,
                //       5,
                //       function (service, cb) {
                //         // consulto el ultimo maintHistory por machineID y por maintCmpActivityID (service)
                //         cx._maintHistoryService
                //           .getLastMaintHistoryByMachineIDandServiceID(
                //             cx.machineModal.id,
                //             service.id,
                //             1
                //           )
                //           .pipe(take(1))
                //           .subscribe((dataMaint) => {
                //             if (dataMaint.length >= 1) {
                //               const dataMAintMap = dataMaint.map(
                //                 d => {
                //                   d.daysUseStart = (new Date(d.dateFinish).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);
                //                   return d
                //                 }
                //               )
                //               cx.historyMaint = cx.historyMaint.concat(
                //                 dataMAintMap
                //               );
                //             }

                //             cb();
                //           });
                //       },
                //       (err: any) => {
                //         cx.loaderOutput.emit(false);
                //         cx.loader = false;
                //       }
                //     );
                //   });
              });
            });
        },
        (err) => {
          this.loaderOutput.emit(false);
          this.loader = false;
        }
      );
  }

  getUnique(arr: any, comp: any) {
    const unique = arr
      .map((e) => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);
    return unique;
  }

  checkMaintCmpActivities(url: string) {
    let foundData1 = false;
    let foundData2 = false;
    if (this.servicesModalSelected.length == 0)
      this._sharedService.changeStateModalMessage({
        typeMsm: TypeMsm.Error,
        titleMsm: "ERROR!",
        textMsm:
          "No se pueden asignar servicios porque no ha seleccionado ninguno",
        textFooter:
          "Para obtener más información, comuníquese con su administrador de sistema.",
      });
    else {
      // recoger la informacion de los servicios antes de mandarlo al componente de aisgnar
      this.servicesModalSelected.forEach((element) => {
        var data: {} = {};
        var data2: {} = {};
        // agregar servicios
        data = this.services.find((x) => x.id == element.maintCmpActivityID);
        if (data) {
          element.maintCmpActivity = data;
          foundData1 = true;
        }
        // agregar servicios de componentes
        data2 = this.servicesComponent.find(
          (x) => x.id == element.maintCmpActivityID
        );
        if (data2) {
          element.maintCmpActivity = data2;
          foundData2 = true;
        }
      });

      // Si se han seleccionado servicios de máquina y de componente mostrar error
      if (foundData1 && foundData2) {
        this._sharedService.changeStateModalMessage({
          typeMsm: TypeMsm.Error,
          titleMsm: "ERROR!",
          textMsm: "No se pueden asignar servicios combinados con componentes",
          textFooter:
            "Para obtener más información, comuníquese con su administrador de sistema.",
        });
        return;
      }
      // Si se han seleccionado servicios de dos componentes diferentes, mostrar error
      let sameSystem = this.getUnique(
        this.servicesModalSelected,
        "systemTypeID"
      ).map((x) => x.systemTypeID);
      let scheckSameSystem = sameSystem.every(function (value, _, array) {
        return array[0] === value;
      });
      if (scheckSameSystem == false) {
        this._sharedService.changeStateModalMessage({
          typeMsm: TypeMsm.Error,
          titleMsm: "ERROR!",
          textMsm: "Seleccione servicios de un solo tipo de componente",
          textFooter:
            "Para obtener más información, comuníquese con su administrador de sistema.",
        });
        return;
      }

      this.goToUrlWithData(url, this.servicesModalSelected);
    }
  }
  checkEditMaintCmpActivities() {
    let foundData1 = false;
    let foundData2 = false;
    if (this.servicesModalSelected.length == 0)
      this._sharedService.changeStateModalMessage({
        typeMsm: TypeMsm.Error,
        titleMsm: "ERROR!",
        textMsm:
          "No se pueden asignar servicios porque no ha seleccionado ninguno",
        textFooter:
          "Para obtener más información, comuníquese con su administrador de sistema.",
      });
    else {
      // recoger la informacion de los servicios antes de mandarlo al componente de aisgnar
      this.servicesModalSelected.forEach((element) => {
        var data: {} = {};
        var data2: {} = {};
        // agregar servicios
        data = this.services.find((x) => x.id == element.maintCmpActivityID);
        if (data) {
          element.maintCmpActivity = data;
          foundData1 = true;
        }
        // agregar servicios de componentes
        data2 = this.servicesComponent.find(
          (x) => x.id == element.maintCmpActivityID
        );
        if (data2) {
          element.maintCmpActivity = data2;
          foundData2 = true;
        }
      });

      // Si se han seleccionado servicios de máquina y de componente mostrar error
      if (foundData1 && foundData2) {
        this._sharedService.changeStateModalMessage({
          typeMsm: TypeMsm.Error,
          titleMsm: "ERROR!",
          textMsm: "No se pueden asignar servicios combinados con componentes",
          textFooter:
            "Para obtener más información, comuníquese con su administrador de sistema.",
        });
        return;
      }
      // Si se han seleccionado servicios de dos componentes diferentes, mostrar error
      let sameSystem = this.getUnique(
        this.servicesModalSelected,
        "systemTypeID"
      ).map((x) => x.systemTypeID);
      let scheckSameSystem = sameSystem.every(function (value, _, array) {
        return array[0] === value;
      });
      if (scheckSameSystem == false) {
        this._sharedService.changeStateModalMessage({
          typeMsm: TypeMsm.Error,
          titleMsm: "ERROR!",
          textMsm: "Seleccione servicios de un solo tipo de componente",
          textFooter:
            "Para obtener más información, comuníquese con su administrador de sistema.",
        });
        return;
      }

      this.stateModalEditMaintenance.emit({ isOk: true, dataService: this.servicesModalSelected });
    }
  }

  checkMaintCmpActivitySelected(machineID: number, maintCmpActivityID: number) {
    var pos = -1;
    pos = this.servicesModalSelected.findIndex(
      (x) =>
        x.machineID == machineID && x.maintCmpActivityID == maintCmpActivityID
    );
    if (pos >= 0) return true;
    else return false;
  }

  getLastMaintHistory(machineID: number, maintCmpActivityID: number) {
    var pos = -1;
    pos = this.historyMaint.findIndex(
      (x) =>
        x.machineID === machineID && x.maintCmpActivityID === maintCmpActivityID
    );
    if (pos >= 0) return this.historyMaint[pos];
    else return {};
  }

  showModalResources(maintHistory): void {
    this.onShowModalResources.emit(maintHistory);
  }

  selectMaintCmpActivity(machineID: number,maintCmpActivityID: number,systemTypeID: number) {
    var pos = -1;
    pos = this.servicesModalSelected.findIndex(
      (x) =>
        x.machineID == machineID && x.maintCmpActivityID == maintCmpActivityID
    );
    if (pos >= 0) this.servicesModalSelected.splice(pos, 1);
    else
      this.servicesModalSelected.push({
        machineID: machineID,
        maintCmpActivityID: maintCmpActivityID,
        systemTypeID: systemTypeID,
      });
  }

  // --------------- funtions for tabs services ---------------

  changeServiceTab(tab) {
    this.serviceTab = tab;
  }


  filterServicesByServiceTypeID(serviceTypeID: number): Service[] {
    // return this.services;
    return this.service.filter(
      (x) => x.serviceTypeID === serviceTypeID
    );
  }

  selectCountController(item: Controller, element: Frecuences[]) {
    let frecuences = element.find(d => d.controllerid == item.id)
    if (frecuences) {
      if (frecuences?.controller?.id == item?.id) {
        return frecuences.frecuencesnum
      }
    }
    return ""
  }

  // Formulas de Horas
  sumHours(item: ControllerUsed[]): number {
    let sum = 0
    item.forEach(element => {
      if (element.controllerid == 1) { // Si el control es horas
        sum += Number(element.value) * 1
      }
    })
    return sum
  }

  sumAsignadas(item: Controller, element: Frecuences[]) {
    let sum = 0
    let frecuences = element.find(d => d.controllerid == item.id)
    if (frecuences) {
      if (frecuences?.controller?.id == item?.id) {
        sum += frecuences.frecuencesnum
      }
    }
    return sum + this.totalHours
  }

  sumCumplidas(item: Controller, element: Frecuences[]) {
    return this.totalHours
  }



}
