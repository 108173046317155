import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';

// Models
import { Machine } from 'src/app/models/class/machine';


// Services
import { SharedService } from 'src/app/services/shared.service';
import { Command } from 'src/app/models/class/command';

@Component({
  selector: 'app-new-machine-info',
  templateUrl: './new-machine-info.component.html',
  styleUrls: ['./new-machine-info.component.sass']
})
export class NewMachineInfoComponent implements OnInit, OnChanges {

  @Input() machine: Machine;
  @Input() viewFullInfo: boolean = true;
  @Input() hideData: boolean = true;
  @Input() scrollCard: boolean = false;
  @Output() statusModalMaintenance: EventEmitter<number> = new EventEmitter<number>();
  @Output() infoMachine: EventEmitter<Machine> = new EventEmitter<Machine>();
  @Input() typeView: number;
  @Input() set validateUpdate(isUpdated: boolean) {
    if (isUpdated) {
      this.hideData = false;
      this.showHistory = true;
    }
  };

  @Input() machinesAssigned: boolean[] = []
  @Input() viewOnlyHeaders: boolean = false
  @Input() horizontalView: boolean = false;
  @Input() activeAccordion:number

  public pendingSave: boolean = false;
  public confirmUpdate: { updated: boolean, dateTime: Date } = { updated: false, dateTime: new Date() };
  public limitHour: number = 23;
  public showHistory: boolean = false;
  public modifyWitdh: boolean = false;

  public myCommand: Command;
  public myUnits: number[];

  constructor(
    public _sharedService: SharedService,
  ) {
    this.myCommand = this._sharedService.getCommand();
    this.myUnits = this._sharedService.getUnits();
  }

  ngOnInit() { }

  showHideHistiy(data: boolean): void {
    this.showHistory = !this.showHistory
    this._sharedService.validateWidthMachineCards(this.showHistory, this.machine.id);
  }

  showHideCards(data: boolean): void {
    this.hideData = data;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.machinesAssigned) {
      this.viewFullInfo = this.machine.subunit != undefined && this.machinesAssigned[0] ? this.machine.subunit != undefined && this.machinesAssigned[0] : this.machine.subunit == undefined && this.machinesAssigned[1]
    }
    if(changes.activeAccordion){
      if(this.machine.id != this.activeAccordion){
        this.hideData = true
      }
    }
  }

  ngOnDestroy() { }

  activateModalMaintenance(machineID: number): void {
    this.statusModalMaintenance.emit(machineID);
  }

  getMachineToGeoLocalizar(item: Machine) {
    this.infoMachine.emit(item)
  }
}
